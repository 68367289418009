import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, ButtonGroup } from '@mui/material';
import { ethers } from 'ethers';
import ItemBar from '../../../../Components/Defi/ItemBar/ItemBar';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import mapImg from '../../../../Resources/Defi/pngwing.com.png';
import { useGetLoanStatisticsQuery } from '../../../../app/services/apiTgLoan';
import CenterPageCircularProgress from '../../../../Components/CircularProgress/CenterPageCircularProgress';
import s from './Statistics.module.scss';
import sDefi from '../../Defi.module.scss';

interface BottomItemProps {
  title: string;
  value: string;
  setValue(title: string): void;
}

const ButtonItem: React.FC<BottomItemProps> = ({ title, value, setValue }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => setValue(title)}
      variant={value === title ? 'contained' : 'outlined'}
      color={value === title ? 'primary' : 'inherit'}
    >
      {t(title)}
    </Button>
  );
};

const Statistics = () => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState('gross_liquidity');

  const { isLoading, data: statistics } = useGetLoanStatisticsQuery();

  if (isLoading) {
    return <CenterPageCircularProgress />;
  }

  const items = ['gross_liquidity', 'available_liquidity', 'pending_refund'];

  const getValue = (v: string) => {
    if (statistics) {
      switch (v) {
        case items[0]:
          return ethers.utils.formatUnits(statistics.total_loan_amount, 18);
        case items[1]:
          return ethers.utils.formatUnits(
            statistics.currently_available_liquidity,
            18
          );
        case items[2]:
          return ethers.utils.formatUnits(statistics.currently_to_refund, 18);
        default:
          return '-';
      }
    }
    return '-';
  };

  return (
    <div className={s.main}>
      <div className={s.left}>
        <div className={s.statisticsBar}>
          {items.map(i => (
            <ItemBar
              title={t(i)}
              value={getValue(i)}
              note={t('matic')}
              key={i}
            />
          ))}
        </div>
        <div className={s.statisticsBarMobile}>
          <div className={s.bastBtnGroup}>
            <ButtonGroup>
              {items.map(i => (
                <ButtonItem
                  title={i}
                  value={currentValue}
                  setValue={setCurrentValue}
                  key={i}
                />
              ))}
            </ButtonGroup>
          </div>
          <Typography variant="h6">
            {getValue(currentValue)} <small>POL</small>
          </Typography>
        </div>
        <div className={s.LiquidityMap}>
          <img src={mapImg} alt="" className={s.mapImg} />
          <div>Liquidity map will appear here soon</div>
        </div>
      </div>
      <div className={sDefi.rightPageContent}>
        <DescriptionPanel content={t('thewall_loan_statistics_description')} />
      </div>
    </div>
  );
};

export default Statistics;
