import React, { useMemo, useState } from 'react';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Popover,
  TextField,
  Typography,
  InputAdornment
} from '@mui/material';
import { bindPopover, PopupState } from 'material-ui-popup-state/hooks';
import { oc } from 'ts-optchain';
import { ActionsPopupBaseProps } from './ActionsPopup/ActionsPopup';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../Hooks/useStores';
import { useReaction } from '../../../Hooks/useReaction';
import { RemoteDataState } from '../../../Utils/RemoteData';
import Web3 from 'web3';
import { observer } from 'mobx-react-lite';
import s from './Popups.module.scss';
import {
  useGetAreaByIdQuery,
  useGetConfigurationQuery
} from '../../../app/services/apiTgTheWall';

const SellPopup = ({
  popupState,
  areaId,
  clusterId,
  areaPremium
}: {
  popupState: PopupState;
  areaId: string;
  clusterId: string;
  areaPremium?: boolean;
}) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const [amount, setAmount] = useState(0);
  const [amountInput, setAmountInput] = useState('');

  const { data: wallConfiguration } = useGetConfigurationQuery();
  const areaOrClusterId = areaId || clusterId;
  const { data: area } = useGetAreaByIdQuery(
    { id: String(areaId) },
    { skip: !areaId || areaPremium != null }
  );

  const operationFee = useMemo(() => {
    if (areaPremium || (area && area.premium)) {
      return 0;
    }
    return +oc(wallConfiguration).feePercents('0') / 100;
  }, [wallConfiguration, area, areaPremium]);

  useReaction(
    () => wallStore.sell.state,
    state => {
      if (state === RemoteDataState.SUCCESS) {
        popupState.close();
      }
    }
  );

  const sell = () => {
    if (areaOrClusterId) {
      wallStore.sellArea(
        areaOrClusterId,
        Web3.utils.toWei(amount.toString(), 'ether')
      );
    }
  };

  const cancel = () => {
    popupState.close();
  };

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.replace(/,/g, '.').replace(/[^\d,.]/g, '');
    if ((value.match(/\./g) || []).length > 1) return;
    setAmountInput(value);
    const valueNumber = parseFloat(value);
    setAmount(isNaN(valueNumber) ? 0 : valueNumber);
  };

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      PaperProps={{
        square: true
      }}
    >
      <div className={s.container}>
        <Typography variant="h5" className={s.title}>
          {t('sell')}
        </Typography>
        <TextField
          value={amountInput}
          onChange={e => handleInput(e)}
          label={t('price')}
          placeholder={t('add')}
          type="text"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            classes: { input: s.input },
            endAdornment: <InputAdornment position="end">POL</InputAdornment>
          }}
        />
        <Typography variant="caption" color="textSecondary">
          {t('sell_fee', {
            value: ((amount || 0) * operationFee).toFixed(2)
          })}
        </Typography>
        <div className={s.buttons}>
          <ButtonBase disableRipple onClick={cancel}>
            <Typography variant="caption">{t('cancel')}</Typography>
          </ButtonBase>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            disabled={!amount || amount <= 0 || wallStore.isSellRequesting}
            onClick={sell}
          >
            {wallStore.isSellRequesting ? (
              <CircularProgress size={12} color="secondary" />
            ) : (
              <Typography variant="caption">{t('sell')}</Typography>
            )}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default observer(SellPopup);
